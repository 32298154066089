<div class="sidebar-header">
  @if (uiService.isXSmallDisplay()) {
    <pearl-button type="icon" icon="close" class="small-display-close-button" (click)="closeProjectSidebar()" />
  }
  <div class="title">My projects</div>
  <div class="search-and-save">
    <pearl-form-field label="Search" *ngIf="config.userInfo?.accessibleOsvProjects?.length > 3">
      <input matInput [(ngModel)]="searchText" autocomplete="off" />
    </pearl-form-field>
    <pearl-button type="primary" [disabled]="!isApplicable" (click)="applyProjectSelection()">
      Apply selection
    </pearl-button>
  </div>
</div>
<div class="sidebar-list">
  <div
    *ngFor="let project of config.userInfo?.accessibleOsvProjects | searchSidebarItem: searchText"
    class="item"
    [ngClass]="{ selected: isSelected(project) }"
    (click)="projectTitleSelected(project)"
    (mouseover)="setHoverMessage($event, project)">
    <mat-checkbox
      [checked]="isSelected(project)"
      [color]="'primary'"
      class="item-selection"
      (change)="toggleProject(project, $event)"
      (click)="$event.stopPropagation()" />
    <div class="item-information">
      <div class="title-box">
        <div class="title">{{ project.title }}</div>
      </div>
    </div>
    <span class="hover-info"> {{ hoverMessage }} </span>
  </div>
</div>
