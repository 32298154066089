import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { filter, firstValueFrom, take } from 'rxjs';

import { Config } from './config';
import { ErrorService } from '../error-pages/error.service';
import { LoadingComponent } from '../shared/loading';
import { InternetStatusService } from '../helpers/internet-status.service';
import { RefDataProvider } from '../data-loader/ref-data-provider';
import { DataLoader } from '../data-loader/data-loader';
import { PersistentCacheService } from '../data-loader/persistent-cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserNavigationGuard {
  private readonly config = inject(Config);
  /** Needed to give to RefDataProvider */
  private readonly dataLoader = inject(DataLoader);
  private readonly router = inject(Router);
  private readonly errorService = inject(ErrorService);
  private readonly persistentCacheService = inject(PersistentCacheService);

  private readonly internetStatusService = inject(InternetStatusService);

  private readonly dialog = inject(MatDialog);

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.errorService.isActive = false;
    this.errorService.urlSource = null;

    if (!this.config || !this.config.loaded || !RefDataProvider.vesselDataLoaded) {
      const taskId = 'refDataLoad';
      const message = 'Loading specs';

      await this.config.load();

      /**
       * This check is required because we init isOnline as true.
       *
       * Thus some DPR endpoints try to reach server on page reload
       * but the server isn't available
       */
      await this.startCheckingInternetStatus();

      LoadingComponent.push(this.dialog, taskId, message);

      if (this.persistentCacheService.isEnabled) {
        await this.persistentCacheService.setup(this.dataLoader);
        // Note: if an endpoint is removed or modified in frontend-persistent-cache-config.json, prune can be long.
        this.persistentCacheService.prune();
      }

      this.config.getSearchbarItems(this.dataLoader);

      await RefDataProvider.loadInitialData(this.dataLoader);

      /*
       * Loading of initial data - specs is loaded after the config because it uses some of the config data,
       * userVesselFleet for ex.
       */
      this.config.specsLoadedResolve(true);
      LoadingComponent.pull(taskId);
    }

    const isNotInReportingOrHasAccessToReportingVessel = !state.url.includes('/dpr/')
      || this.config.hasAccessToVessel(route.queryParams?.vesselId);
    if (this.config.checkRightsFromUrl(state.url) && isNotInReportingOrHasAccessToReportingVessel) {
      return true;
    }

    this.errorService.urlSource = state.url;
    this.router.navigate(['/no-rights']);
    return false;
  }

  private async startCheckingInternetStatus(): Promise<void> {
    if (this.config.product === 'osv') {
      await firstValueFrom(this.internetStatusService.internetServiceReady$.pipe(
        filter(value => value),
        take(1),
      ));
    }
  }
}
