import { ModuleWithProviders, NgModule } from '@angular/core';

import { NoRightComponent } from './no-rights';
import { ErrorService } from './error.service';
import { BadParameterComponent } from './bad-parameter';
import { PageNotFoundComponent } from './page-not-found';

@NgModule({
  imports: [
    NoRightComponent,
    BadParameterComponent,
    PageNotFoundComponent,
  ],
  exports: [
    NoRightComponent,
    BadParameterComponent,
    PageNotFoundComponent,
  ],
})
export class ErrorModule {
  static forRoot(): ModuleWithProviders<ErrorModule> {
    return {
      ngModule: ErrorModule,
      providers: [ErrorService],
    };
  }
}
