<div data-testid="fleet-sidebar">
  <div class="sidebar-header">
    @if (uiService.isXSmallDisplay()) {
      <pearl-button type="icon" icon="close" class="small-display-close-button" (click)="closeVesselFleetSidebar()" />
    }
    <div class="title">
      My Fleets
      <div class="hint">
        Hold <kbd>⌘</kbd> or <kbd>Ctrl</kbd> <br />
        to add to selection
      </div>
    </div>

    <div class="search-and-save">
      <pearl-form-field label="Search ...">
        <input matInput [(ngModel)]="searchText" autocomplete="off" />
      </pearl-form-field>
      <div class="header-buttons">
        <!-- Following div is used so that the tooltip is shown when the button is disabled -->
        <div
          [spinTooltip]="
            createFleetDisabled ? 'You cannot create a vessel fleet if you are already applying a fleet' : null
          ">
          @if (!uiService.isXSmallDisplay()) {
            <pearl-button
              type="primary"
              icon="add"
              class="header-button"
              (click)="createVesselFleet()"
              [disabled]="createFleetDisabled">
              Create new fleet
            </pearl-button>
          }
        </div>
        @if (config.selectedFleets?.length) {
          <pearl-button
            type="primary"
            mode="warning"
            icon="move_last"
            class="leave-all-fleet-button"
            (click)="leaveAllFleet()">
            Return to main fleet
          </pearl-button>
        }
      </div>
    </div>
  </div>
  <div id="top"></div>
  <div class="sidebar-list" [ngClass]="{ selected: config.selectedFleets }">
    <vessel-fleet-list
      [vesselFleets]="sharedVesselFleets"
      [listTitle]="'Shared fleets'"
      [searchText]="searchText"
      [displayTitle]="sharedVesselFleets.length > 0"
      [vesselDataset]="vesselDataset"
      [canEdit]="config.canShareFleet()"
      (onselect)="onSelectVesselFleet()"
      (shareFleet)="updateListAfterShared($event)">
    </vessel-fleet-list>
    <vessel-fleet-list
      [vesselFleets]="vesselFleets"
      [listTitle]="'My fleets'"
      [searchText]="searchText"
      [displayTitle]="sharedVesselFleets.length > 0"
      [vesselDataset]="vesselDataset"
      [canEdit]="true"
      (onselect)="onSelectVesselFleet()"
      (shareFleet)="updateListAfterShared($event)">
    </vessel-fleet-list>
  </div>
</div>
