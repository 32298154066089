import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function startAngularApp(): void {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(moduleRef => {
      /**
       * In a development environment, enable Angular debugTools
       * Gives access to `ng.profiler` in console & profiler details using Angular DevTools
       *
       * @see https://github.com/angular/angular/blob/main/docs/TOOLS.md
       * @see https://angular.io/api/platform-browser/enableDebugTools
       * @see https://chrome.google.com/webstore/detail/angular-devtools/ienfalfjdbdpebioblfackkekamfmbnh
       */

      if (!environment.production) {
        const applicationRef = moduleRef.injector.get(ApplicationRef);
        const componentRef = applicationRef.components[0];
        enableDebugTools(componentRef);
      }
    })
    .catch(err => console.error(err));
}

/*
 * Check if the user is authenticated
 * If not, redirect him to the login page given by the endpoint
 * If we don't have a correct HTTP response, we will still load the app (this enables offline loading)
 */
const baseUrl = document.location.origin;
const authUrl = `${baseUrl}/sso/web-auth-redirect`;
fetch(authUrl).then(baseResponse => {
  /*
   * Check if we obtained an OK response from our auth-redirect endpoint
   * We can maybe limit this to 504 responses (Gateway timeout):
   * if (baseResponse.status != 504)
   */
  if (baseResponse.status >= 200 && baseResponse.status < 300) {
    baseResponse.json().then(isAuthenticated => {
      // Redirect to login URL if user is not authenticated
      if (!isAuthenticated.success && isAuthenticated.redirectUrl) {
        // Adding the _target_path parameter enables Symfony to redirect to this url after login
        const redirectUrl = `${isAuthenticated.redirectUrl}?_target_path=${encodeURIComponent(document.location.href)}`;
        document.location.assign(redirectUrl);
        return;
      } // This case should not happen - User is not authenticated but no redirection URL was provided
      else if (!isAuthenticated.success) {
        throw Error('User is not authenticated / enabled but no redirection has been provided.');
      }

      startAngularApp();
    });
  } else {
    startAngularApp();
  }
});
