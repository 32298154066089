<div>
  <h3 *ngIf="displayTitle" class="fleet-list-title">{{ listTitle }}</h3>
  <div
    *ngFor="let vesselFleet of vesselFleets | searchSidebarItem: searchText"
    class="item"
    (click)="selectVesselFleet($event, vesselFleet)"
    [ngClass]="{ selected: vesselFleet.selected }"
    data-testid="fleet-sidebar-item">
    <div class="item-information">
      <div>
        <div class="title" *ngIf="!vesselFleet.editing">{{ vesselFleet.title }}</div>
        <div class="title-editing" *ngIf="vesselFleet.editing">
          <pearl-form-field
            label="Enter your title"
            [showError]="vesselFleet.newTitle.invalid"
            [errorMessage]="getErrorMessage(vesselFleet.newTitle)"
            [small]="true">
            <input
              matInput
              #titleInput
              (keydown.enter)="onTitleEnter($event, vesselFleet)"
              [formControl]="vesselFleet.newTitle"
              required
              cdkFocusInitial
              autocomplete="off" />
          </pearl-form-field>
        </div>
        <mat-checkbox
          *ngIf="config.canShareFleet() && vesselFleet.editing"
          class="share-fleet-checkbox mat-primary"
          [matTooltip]="'Share your fleet within ' + config.userInfo.client"
          matTooltipPosition="after"
          [color]="'primary'"
          [(ngModel)]="vesselFleet.newSharedFleet">
          Shared fleet
        </mat-checkbox>
      </div>
      <div class="title">{{ vesselFleet.dashboard | upperFirstLetter }}</div>
      <div class="date">
        {{ getFormattedDate(vesselFleet.dateVesselFleet) }}
        <ng-container *ngIf="config.canShareFleet() && vesselFleet.editing">
          <span> - </span>
          <span> Fleet ID: {{ vesselFleet.id }} </span>
        </ng-container>
      </div>
      <div class="subtitle">{{ vesselFleetCountInfo(vesselFleet) }}</div>
    </div>
    <div *ngIf="!vesselFleet.editing && !vesselFleet.deleting">
      @if (vesselFleet.bookmarks && vesselFleet.bookmarks.length) {
        <pearl-icon
          icon="bookmark"
          [size]="24"
          matBadge="{{ vesselFleet.bookmarks.length }}"
          matBadgeSize="small"
          matBadgeColor="accent">
        </pearl-icon>
      }
    </div>
    <div class="action-buttons">
      @if (canEdit && !vesselFleet.editing && !vesselFleet.deleting) {
        <pearl-button
          type="icon"
          icon="more"
          class="more-button"
          [matMenuTriggerFor]="addMenu"
          matTooltip="Actions"
          matTooltipPosition="above"
          (click)="preventDefault($event)" />
      }
      <mat-menu #addMenu="matMenu" class="edit-menu">
        @if (!vesselFleet.editing && canEdit) {
          <pearl-button
            type="icon"
            icon="edit"
            class="edit-button"
            (click)="enterEditMode($event, vesselFleet)"
            [matTooltip]="'Edit'" />
        }
        @if (!vesselFleet.editing) {
          <pearl-button
            type="icon"
            icon="delete"
            mode="warning"
            matTooltip="Delete"
            class="delete-button"
            (click)="enterDeletingMode($event, vesselFleet)" />
        }
      </mat-menu>
      @if (vesselFleet.editing) {
        <pearl-button
          type="icon"
          icon="sort"
          class="edit-filters-button"
          (click)="loadExistingVesselFleet(vesselFleet)"
          matTooltip="Edit filters" />
      }
      @if (vesselFleet.editing) {
        <pearl-button
          type="icon"
          icon="save"
          class="save-button"
          (click)="saveClick($event, vesselFleet)"
          [disabled]="!vesselFleet.newTitle.value"
          matTooltip="Save" />
      }
      @if (vesselFleet.deleting) {
        <pearl-button
          type="icon"
          icon="delete"
          mode="warning"
          class="delete-button"
          (click)="checkIfCanDeleteVesselFleet($event, vesselFleet)"
          matTooltip="Delete" />
      }
      @if (vesselFleet.editing || vesselFleet.deleting) {
        <pearl-button
          type="icon"
          icon="cancel"
          class="cancel-button"
          (click)="cancelAction($event, vesselFleet)"
          mat-icon-button
          matTooltip="Cancel" />
      }
    </div>
  </div>
</div>
