import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgIf } from '@angular/common';

import { ErrorService } from './error.service';

@Component({
  selector: 'no-right',
  templateUrl: './no-rights.html',
  styleUrls: ['no-rights.css'],
  standalone: true,
  imports: [NgIf],
})
export class NoRightComponent {
  private _preciseMessage: string = null;

  constructor(
    public readonly titleService: Title,
    public readonly errorService: ErrorService,
  ) {
    this.errorService = errorService;
    this.errorService.isActive = true;
    this.titleService = titleService;
    titleService.setTitle('Spinergie | Insufficient rights');
  }

  public get errorPreciseMessage(): string {
    if (this._preciseMessage) {
      return this._preciseMessage;
    }
    const url = this.errorService.urlSource;
    const getTitleRegex = new RegExp('/dashboard/([^/;?]*)(/[^/;?]*)?', 'i');
    const regexResult = getTitleRegex.exec(url);
    /** Handle edge case: no rights found, but not from a dashboard page */
    if (regexResult == null) return '';
    const isDashboard = regexResult[1] !== 'page';
    this._preciseMessage = `you are trying to access the url : ${url}\n`
      + `
        but you don't have the right to access
        ${isDashboard ? `the ${regexResult[1]} dashboard` : `a ${regexResult[2]} page`}
      `;
    return this._preciseMessage;
  }

  reload(): void {
    this._preciseMessage = null;
    this.errorService.isActive = true;
    this.titleService.setTitle('Spinergie | Insufficient rights');
  }
}
