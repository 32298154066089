@if (currentApplication()) {
  @if (isOpenable()) {
    <button
      #appSwitcherTrigger
      class="trigger openable"
      [pearlMenuTriggerFor]="appSwitcherMenu"
      [ngClass]="{
        large: uiService.isLargeDisplay(),
        active: isTriggerActive(),
        marketIntel: currentApplication().title.startsWith('Market Intelligence'),
      }"
      [matTooltip]="'Switch application'"
      [matTooltipShowDelay]="1000"
      (click)="onTriggerClick()"
      (menuOpened)="onMenuOpened()"
      (menuClosed)="onMenuClosed()">
      <ng-container *ngTemplateOutlet="triggerContent" />
    </button>
    <pearl-menu #appSwitcherMenu [categories]="menuCategories()" [panelWidth]="triggerWidth()" />
  } @else {
    @if (uiService.isLargeDisplay()) {
      <button
        class="trigger"
        routerLink="/"
        [ngClass]="{ large: uiService.isLargeDisplay(), active: isTriggerActive() }">
        <ng-container *ngTemplateOutlet="triggerContent" />
      </button>
    }
  }
}

<ng-template #triggerContent>
  @if (uiService.isLargeDisplay()) {
    <div class="title-ctn">
      <div class="title">{{ currentApplication().title }}</div>
      <div class="subtitle">by <span class="spinergie">Spinergie</span></div>
    </div>
  }
  @if (isOpenable()) {
    <pearl-icon icon="chevron_down" [size]="40" />
  }
</ng-template>
