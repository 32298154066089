import { ChangeDetectionStrategy, Component, ElementRef, Signal, ViewChild, WritableSignal, computed, inject, input,
  signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

import { groupBy } from 'lodash-es';

import { UIService } from '../shared/services/ui.service';
import {
  PearlMenuTriggerForDirective,
} from '../shared/pearl-components/components/menu/pearl-menu-trigger-for.directive';
import { PearlMenuCategory, PearlMenuComponent,
  PearlMenuItem } from '../shared/pearl-components/components/menu/pearl-menu.component';
import { OtherApplication } from '../helpers/app-types';
import { Config } from '../config/config';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../shared/confirm-dialog/confirm-dialog';
import { PearlIconComponent } from '../shared/pearl-components';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';

@Component({
  selector: 'app-switcher',
  templateUrl: 'app-switcher.component.html',
  styleUrl: 'app-switcher.component.scss',
  imports: [
    MatTooltip,
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    PearlIconComponent,
    PearlMenuComponent,
    PearlMenuTriggerForDirective,
    RouterLink,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSwitcherComponent {
  @ViewChild('appSwitcherTrigger')
  protected trigger: ElementRef<HTMLElement>;

  protected uiService: UIService = inject(UIService);
  protected dialog: MatDialog = inject(MatDialog);
  private config: Config = inject(Config);
  private analyticsService: ProductAnalyticsService = inject(ProductAnalyticsService);

  public readonly applications = input.required<OtherApplication[]>();

  protected isTriggerActive: WritableSignal<boolean> = signal(false);
  protected triggerWidth: WritableSignal<number> = signal<number>(undefined);

  protected currentApplication: Signal<OtherApplication> = computed(() => {
    return this.applications().find(application => application.isCurrentApplication);
  });

  /**
   * Get the menu categories to pass to pearl menu.
   *
   * Spinergie web apps are returned with no category and go first.
   * Other apps can be returned with a category. They go after Spinergie web apps and are grouped by category.
   */
  protected menuCategories: Signal<PearlMenuCategory[]> = computed(() => {
    const menuCategories: PearlMenuCategory[] = [];
    const appsWithoutCategory = this.applications().filter(app => !app.category);
    const appsByCategory = groupBy(
      this.applications().filter(app => app.category),
      (app: OtherApplication) => app.category,
    );

    // Un-categorized apps (Spinergie web apps) go first
    if (appsWithoutCategory.length) {
      menuCategories.push({
        items: appsWithoutCategory.map(app => this.createMenuItemFromApplication(app)),
      });
    }

    // Then other apps, grouped by category
    for (const category in appsByCategory) {
      menuCategories.push({
        label: category,
        items: appsByCategory[category].map(app => this.createMenuItemFromApplication(app)),
      });
    }

    return menuCategories;
  });

  protected readonly isOpenable: Signal<boolean> = computed(() => {
    return this.applications().length > 1;
  });

  protected onAppItemClick(application: OtherApplication): void {
    if (application.isCurrentApplication) return;

    const targetUrl = application.isSpinergieApplication
      ? `${application.url}/sso/form?_username=${this.config.userInfo.spinergieUserEmail}`
      : application.url;

    if (!application.isSpinergieApplication || this.config.appParams.env === 'prod') {
      window.open(targetUrl, '_blank');
      this.analyticsService.trackAction('appSwitcherLinkClicked', { targetUrl: application.url });

      return;
    }

    // Warn the user that they are being redirected from a non-prod env to a prod env
    const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogModel>(ConfirmDialogComponent, {
      data: {
        title: 'Changing environment',
        message:
          `You are leaving the current ${this.config.appParams.env} environment and are being redirected to the prod environment. Proceed?`,
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) return;
      window.open(targetUrl, '_blank');
    });
  }

  protected onTriggerClick(): void {
    // Update this.triggerWidth so that the menu panel has the same width as the trigger on large displays
    this.triggerWidth.set(this.uiService.isLargeDisplay() ? this.trigger.nativeElement.offsetWidth : undefined);
  }

  protected onMenuOpened(): void {
    this.isTriggerActive.set(true);
    this.analyticsService.trackAction('appSwitcherMenuOpened');
  }

  protected onMenuClosed(): void {
    this.isTriggerActive.set(false);
    this.trigger.nativeElement.blur();
  }

  private createMenuItemFromApplication(application: OtherApplication): PearlMenuItem {
    const menuItem: PearlMenuItem = {
      label: application.title,
      supportingText: application.subtitle,
      active: application.isCurrentApplication,
    };

    if (!application.isCurrentApplication) {
      menuItem.onClick = (): void => this.onAppItemClick(application);
      menuItem.trailingIcon = 'open_in_new';
    }

    return menuItem;
  }
}
