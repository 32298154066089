<ng-container *ngIf="data.deleted">
  <h1 mat-dialog-title class="delete-fleet-title">Delete your fleet</h1>
  <div mat-dialog-content>
    <div class="delete-fleet-warning" *ngIf="data.bookmarks?.length">
      Warning, the vessel fleet you are trying to remove is linked to the following bookmarks:
      <ul>
        <li *ngFor="let bookmark of data.bookmarks">{{ bookmark }}</li>
      </ul>
      Deleting the vessel fleet will result in the deletion of these bookmarks.
    </div>
    <div class="delete-fleet-warning" *ngIf="data.linkedAlerts?.length">
      Warning, the vessel fleet you are trying to remove is linked to the following alerts:
      <ul>
        <li *ngFor="let alert of data.linkedAlerts">{{ alert.subscriptionTitle }}</li>
      </ul>
      Deleting the vessel fleet will result in the deletion of these alerts.
    </div>
    <div mat-dialog-actions class="fleet-dialog-buttons">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button class="confirm-button" [mat-dialog-close]="data.bookmarks" cdkFocusInitial>Delete</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="data.modified">
  <div mat-dialog-content>Do you want to apply the fleet you modified?</div>
  <div mat-dialog-actions class="fleet-dialog-buttons">
    <button mat-button (click)="onNoClick()">Later</button>
    <button mat-button class="confirm-button" [mat-dialog-close]="data.bookmarks" cdkFocusInitial>Apply</button>
  </div>
</ng-container>
