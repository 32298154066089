<ng-template #logout>
  <a
    id="buttonAsLink"
    href="{{ logoutUrl }}"
    mat-menu-item
    [analyticsAction]="{ title: 'userLoggedOut', trackedValues: {} }">
    <pearl-icon icon="logout" [size]="24" />
    Logout
  </a>
</ng-template>
<div class="app">
  <header id="header" class="navbar-header">
    <div class="left-part-header">
      <a class="navbar-brand" [routerLink]="['/']">
        <img [src]="uiService.appLogo()" (load)="collapseMenuIfNecessary()" />
      </a>
      @if (config.userRights?.otherApplications) {
        <app-switcher [applications]="config.userRights.otherApplications" />
      }
      <nav class="spin-menu" *ngIf="collapseMenu && (!uiService.isXSmallDisplay() || this.config.product === 'osv')">
        <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
          <ng-template matMenuContent>
            <div class="menu-filling-panel">
              <div *ngFor="let panel of panels">
                <div *ngIf="canShowPanel(panel)">
                  <div [ngSwitch]="panel.type">
                    <div *ngSwitchCase="'submenu'">
                      <div
                        [matMenuTriggerFor]="submenu"
                        [class.selected]="isSelected(panel)"
                        class="spin-menu-item"
                        mat-menu-item>
                        <pearl-icon *ngIf="panel.icon" [icon]="panel.icon" [size]="24" />
                        {{ panel.menuTitle }}
                        <i *ngIf="panel.mark" class="mark"> {{ panel.mark.text }} </i>
                      </div>
                      <mat-menu #submenu>
                        <div class="menu-filling-panel">
                          <div *ngFor="let subpanel of getSubpanels(panel)">
                            <a
                              *ngIf="canShowPanel(subpanel)"
                              class="spin-menu-item"
                              [routerLink]="[getPanelRouterLink(subpanel)]"
                              [queryParams]="getPanelRouterLinkParams()"
                              (click)="reloadPage($event)"
                              [class.selected]="isSelected(subpanel)"
                              mat-menu-item>
                              <pearl-icon *ngIf="subpanel.icon" [icon]="subpanel.icon" [size]="24" />
                              {{ subpanel.menuTitle }}
                              <i *ngIf="subpanel.mark" class="mark">{{ subpanel.mark.text }}</i>
                            </a>
                          </div>
                        </div>
                      </mat-menu>
                    </div>
                    <a
                      *ngSwitchCase="'link'"
                      href="{{ getPanelLink(panel) }}"
                      target="_blank"
                      class="spin-menu-item"
                      [class.selected]="isSelected(panel)"
                      mat-menu-item>
                      <pearl-icon *ngIf="panel.icon" [icon]="panel.icon" [size]="24" />
                      {{ panel.menuTitle }}
                      <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
                    </a>
                    <a
                      *ngSwitchDefault
                      [routerLink]="[getPanelRouterLink(panel)]"
                      [queryParams]="getPanelRouterLinkParams()"
                      class="spin-menu-item"
                      (click)="reloadPage($event)"
                      [class.selected]="isSelected(panel)"
                      mat-menu-item>
                      <pearl-icon *ngIf="panel.icon" [icon]="panel.icon" [size]="24" />
                      {{ panel.menuTitle }}
                      <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-menu>

        <pearl-button
          type="alternative"
          icon="menu"
          class="hidden-menu-button"
          [ngClass]="{ 'menu-button-full-width': !collapseTextMenu }"
          [matMenuTriggerFor]="appMenu">
          @if (!collapseTextMenu) {
            <span class="menu-text"> Menu </span>
          }
        </pearl-button>
      </nav>
      <!-- Non collapsed menu -->
      <nav class="spin-menu" *ngIf="!collapseMenu">
        <ng-container *ngFor="let panel of panels">
          <ng-container *ngIf="canShowPanel(panel)">
            <ng-container *ngIf="panel.type == 'submenu'">
              <div
                [matMenuTriggerFor]="submenu"
                [class.selected]="isSelected(panel)"
                class="spin-menu-item"
                mat-menu-item>
                {{ panel.menuTitle }}
                <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
                <pearl-icon class="spin-menu-item-icon" icon="chevron_down" [size]="24" />
              </div>

              <mat-menu #submenu>
                <div class="menu-filling-panel">
                  <ng-container *ngFor="let subpanel of panel.subpanels">
                    <a
                      *ngIf="canShowPanel(subpanel)"
                      class="spin-menu-item"
                      routerLinkActive="active"
                      [routerLink]="[getPanelRouterLink(subpanel)]"
                      [queryParams]="getPanelRouterLinkParams()"
                      (click)="reloadPage($event)"
                      [class.selected]="isSelected(subpanel)"
                      mat-menu-item>
                      {{ subpanel.menuTitle }}
                      <i *ngIf="subpanel.mark" class="mark"> {{ subpanel.mark.text }} </i>
                    </a>
                  </ng-container>
                </div>
              </mat-menu>
            </ng-container>

            <a
              *ngIf="panel.type == 'link'"
              href="{{ getPanelLink(panel) }}"
              target="_blank"
              [class.selected]="isSelected(panel)"
              class="spin-menu-item"
              mat-menu-item>
              <span class="title">{{ panel.menuTitle }}</span>
              <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
            </a>

            <a
              *ngIf="panel.type != 'submenu' && panel.type != 'link'"
              routerLinkActive="active"
              [routerLink]="[getPanelRouterLink(panel)]"
              [queryParams]="getPanelRouterLinkParams()"
              class="spin-menu-item"
              (click)="reloadPage($event)"
              [class.selected]="isSelected(panel)"
              mat-menu-item>
              <span class="title">{{ panel.menuTitle }}</span>
              <i *ngIf="panel.mark" class="mark">{{ panel.mark.text }}</i>
            </a>
          </ng-container>
        </ng-container>
      </nav>
    </div>
    <div class="right-part-header">
      <div class="right-menu">
        <div class="internet-status" *ngIf="routerService.isOfflineViewActivated$ | async">
          <mat-slide-toggle
            class="internet-status-slide-toggle"
            [disabled]="!internetStatusService.internetAvailable()"
            [checked]="internetStatusService.internetUp$ | async"
            (toggleChange)="internetStatusToggleChange()" />
          <ng-container *ngIf="internetStatusService.internetUp$ | async; else internetOffline">
            <pearl-icon icon="online" [size]="24" class="internet-icon online-color" />
            <span class="online-color">Online</span>
          </ng-container>
          <ng-template #internetOffline>
            <pearl-icon icon="offline" [size]="24" class="internet-icon offline-color" />
            <span class="offline-color">Offline</span>
          </ng-template>
        </div>
        <search-bar
          #search_bar
          id="main-search-bar"
          [ngClass]="{ 'menu-search-bar-hidden': !hasSearchAccess }"
          [searchLabel]="searchLabel()"
          [highlighted]="true"
          [hasLabel]="false"
          [searchItemsAvailable]="config.searchItemsAvailable"
          [recentSearch]="searchItems"
          [clearAfterSelect]="true"
          source="main"
          (onoptionselected)="optionSelected($event)"
          [resultTypeOrder]="config.searchBarResultOrder"
          [canRequestVessel]="canRequestNewVessel()" />

        @if (!uiService.isXSmallDisplay() && config.vesselFleetVisible) {
          <pearl-button
            type="icon"
            icon="vessel"
            matTooltip="My fleet"
            iconBadgeContent="{{ config.selectedFleets?.length }}"
            (click)="sideMenuToggle(SideMenuEnum.Fleet)"
            [ngClass]="{ 'side-menu-open': openedSideMenu === SideMenuEnum.Fleet || config.selectedFleets?.length }" />
        }
        @if (!uiService.isXSmallDisplay() && hasOsvProjects()) {
          <pearl-button
            type="icon"
            icon="project"
            matTooltip="Projects"
            iconBadgeContent="{{ getNumberSelectedOsvProjects() }}"
            (click)="sideMenuToggle(SideMenuEnum.Project)"
            [ngClass]="{ 'side-menu-open': openedSideMenu === SideMenuEnum.Project }">
          </pearl-button>
        }
        @if (!uiService.isXSmallDisplay()) {
          <pearl-button
            type="icon"
            icon="bookmark"
            matTooltip="Bookmarks"
            (click)="sideMenuToggle(SideMenuEnum.Bookmark)">
          </pearl-button>
        }
        @if (!uiService.isXSmallDisplay() && config.product != "analysts") {
          <pearl-button
            type="icon"
            icon="alert"
            matTooltip="Reports & Alerts"
            routerLink="/dashboard/global-alert-subscription">
          </pearl-button>
        }
        @if (config.userRights?.hasResources && !uiService.isXSmallDisplay()) {
          <pearl-button type="icon" icon="resource" matTooltip="Resources" routerLink="/dashboard/resource-gallery">
            <i *ngIf="!config.isMarketIntel" class="mark">NEW</i>
          </pearl-button>
        }
        @if (config.appConfig?.timezoneSelector && !uiService.isXSmallDisplay()) {
          <pearl-button
            type="icon"
            icon="timezone"
            matTooltip="Time zone"
            [matMenuTriggerFor]="timezoneMenu"
            [disabled]="timezoneService.disabled"
            [ngClass]="{
              active: !timezoneService.disabled && timezoneService.localTimezone != 'local',
            }">
          </pearl-button>
        }
        @if (hasDatabaseOverviewAccess() && !uiService.isXSmallDisplay()) {
          <pearl-button
            type="icon"
            icon="database"
            matTooltip="Database overview"
            routerLink="/dashboard/database-overview" />
        }
        @if (!uiService.isXSmallDisplay()) {
          <pearl-button type="icon" icon="profil" id="person-menu" [matMenuTriggerFor]="rightMenu" />
        } @else {
          <pearl-button
            type="icon"
            icon="more"
            id="person-small-display-menu"
            [matMenuTriggerFor]="smallDisplayRightMenu"
            class="person-small-display-menu" />
        }

        <mat-menu #smallDisplayRightMenu class="spin-menu-item">
          <div class="menu-filling-panel">
            <ng-container *ngTemplateOutlet="logout"></ng-container>
            @if (config.userRights?.hasResources) {
              <pearl-button
                class="small-display-action-button"
                routerLink="/dashboard/resource-gallery"
                type="alternative"
                icon="resource"
                >Resources</pearl-button
              >
            }

            @if (config.vesselFleetVisible) {
              <pearl-button
                class="small-display-action-button"
                type="alternative"
                icon="vessel"
                iconBadgeContent="{{ config.selectedFleets?.length }}"
                (click)="sideMenuToggle(SideMenuEnum.Fleet)">
                My fleet
              </pearl-button>
            }
            @if (config?.userInfo?.accessibleOsvProjects?.length > 1) {
              <pearl-button
                class="small-display-action-button"
                type="alternative"
                icon="project"
                iconBadgeContent="{{ getNumberSelectedOsvProjects() }}"
                (click)="sideMenuToggle(SideMenuEnum.Project)">
                Projects
              </pearl-button>
            }
          </div>
        </mat-menu>
        <mat-menu #rightMenu class="spin-menu-item">
          <div class="menu-filling-panel">
            <ng-container *ngTemplateOutlet="logout"></ng-container>
            <a
              *ngFor="let builtInPanel of rightMenuDashboards"
              [routerLink]="[getPanelRouterLink(builtInPanel)]"
              [queryParams]="getPanelRouterLinkParams()"
              class="spin-menu-item"
              (click)="reloadPage($event)"
              mat-menu-item
              [class.selected]="isSelected(builtInPanel)">
              <pearl-icon [icon]="builtInPanel.icon ? builtInPanel.icon : 'settings'" [size]="24" />
              {{ builtInPanel.menuTitle }}
              <i *ngIf="builtInPanel.mark" class="mark">{{ builtInPanel.mark.text }}</i>
            </a>
            <a *ngIf="hasApiDocs" target="_blank" [href]="apiDocsUrl" mat-menu-item class="spin-menu-item">
              <pearl-icon icon="api" [size]="24" />
              API Docs
            </a>
            <!-- By default, clicking on a menu item closes the menu. But here, we want it to stay open when we click
                 in the search bar. We still want the event to propagate outside the search bar by default so all search
                bar close when we have many on a page. This fix is linked to this specific behavior. -->
            <div
              *ngIf="canImpersonate && !isImpersonate"
              class="impersonate right-menu"
              (click)="$event.stopPropagation()">
              <search-bar
                [searchItemsAvailable]="usersForImpersonation"
                searchLabel="type user..."
                source="impersonate"
                [clearAfterSelect]="true"
                (onoptionselected)="impersonate($event)" />
            </div>
          </div>
        </mat-menu>
        <mat-menu #timezoneMenu class="spin-menu-item">
          <div class="menu-filling-panel">
            <a *ngFor="let tz of timezones" class="spin-menu-item" (click)="setTimezone(tz.code, $event)" mat-menu-item>
              @if (tz.code == timezoneService.localTimezone) {
                <pearl-icon icon="checked" [size]="24" />
              }
              {{ tz.title }}
            </a>
            <div class="impersonate right-menu">
              <!-- Change by an input like in entity-detail-field -->
              <search-bar
                [searchItemsAvailable]="availableTimezones"
                searchLabel="Custom timezone..."
                [clearAfterSelect]="true"
                (onoptionselected)="appendTimezone($event)"
                (click)="$event.stopPropagation()" />
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </header>

  <mat-drawer-container [hasBackdrop]="false" class="dashboard-container">
    <mat-drawer
      class="right-sidebar"
      #sideDrawer
      [mode]="'over'"
      [position]="'end'"
      *ngIf="true"
      (openedChange)="bookmarkStateChange($event)">
      <!-- we use [hidden] on the component below, so that when the sidebar is collapsed, it's still reachable. -->
      <vessel-fleet-sidebar
        *ngIf="config.vesselFleetVisible && fleetSidebarLoaded && config.specsLoadedPromise | async"
        [hidden]="openedSideMenu !== SideMenuEnum.Fleet"
        #vesselFleetSidebar
        (vesselFleetSelected)="onVesselFleetSelected($event)" />
      <project-sidebar
        *ngIf="hasOsvProjects()"
        [hidden]="openedSideMenu !== SideMenuEnum.Project"
        (osvProjectSelected)="onProjectScopeSelect($event)"
        (osvProjectApplied)="closeAndApply()" />
      <bookmarker
        *ngIf="config.loaded"
        [hidden]="openedSideMenu !== SideMenuEnum.Bookmark"
        #bookmarks
        [currentDashboard]="selectedPanelTitle"
        (onBookmarkSelect)="onBookmarkSelect($event)" />
    </mat-drawer>
    <mat-drawer-content>
      <pearl-button
        type="secondary"
        icon="bookmark"
        class="save-analysis-button"
        [ngClass]="{ 'save-analysis-hidden': !displaySaveAnalysis || userHideSaveAnalysis }"
        (click)="saveAnalysis()">
        Save current analysis
        <pearl-button type="icon" icon="close" class="save-analysis-close" (click)="hideSaveAnalysisClick($event)" />
      </pearl-button>
      <router-outlet />

      <div *ngIf="!(config.specsLoadedPromise | async)" class="app-loading-container">
        <div class="spinning-logo"></div>
        <div class="app-loading-text">Preparing application <span>.</span><span>.</span><span>.</span></div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>

  <footer id="footer" class="navbar">
    <div class="container-fluid">
      <p class="footer-text">&copy; Spinergie - All rights reserved</p>
      <p class="footer-text" *ngIf="!uiService.isXSmallDisplay()">{{ bottomText }}</p>
      <p class="footer-text">{{ webAppVersion }}</p>
    </div>
  </footer>
</div>

<div
  *ngIf="impersonatingUser"
  class="impersonation"
  [ngStyle]="impersonationStyle"
  (click)="showHideImpersonation(true, $event)">
  You are viewing Spinergie as {{ impersonatingUser }}, except private data
  <span *ngIf="!userActivated">(Deactivated)</span>
  <div class="impersonation-buttons">
    <div (click)="endImpersonation()">Quit</div>
    <div (click)="showHideImpersonation(false, $event)">Hide</div>
  </div>
</div>

<div *ngIf="appLoaded">
  <div *ngIf="isInDevMode()" class="dev-toolbox">
    <mat-expansion-panel
      class="dev-toolbox-expansion-panel"
      (opened)="expandDevToolbox(true)"
      (closed)="expandDevToolbox(false)"
      [expanded]="canExpandDevToolbox()">
      <mat-expansion-panel-header collapsedHeight="25px">
        <mat-panel-title>
          <pearl-icon icon="menu" [size]="24" />
          Dev tools
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="dev-toolbox-cell">
        <mat-slide-toggle [checked]="getVesselAutoReloadState()" (toggleChange)="updateVesselAutoreloadState()" />
        Vessel Autoreload
      </div>
      <div class="dev-toolbox-cell">
        <mat-slide-toggle [checked]="getInternetAutoReloadState()" (toggleChange)="updateInternetAutoreloadState()" />
        Internet Autoreload
      </div>
      <div class="dev-toolbox-cell">
        <mat-slide-toggle [checked]="getDevSetting('mocksActive')" (toggleChange)="toggleDevSetting('mocksActive')" />
        Mocks active
      </div>
      <div class="dev-toolbox-cell">
        <mat-slide-toggle
          [checked]="getDevSetting('disablePersistentCache')"
          (toggleChange)="toggleDevSetting('disablePersistentCache')" />
        Disable persistent cache
      </div>
      <div class="dev-toolbox-cell">
        <pearl-button type="icon" icon="refresh" (click)="reloadPageConfig()" aria-label="refresh"></pearl-button>
        Refresh config
      </div>
    </mat-expansion-panel>
  </div>
</div>

<!-- This simple-tooltip is used all over the App thanks to the SimpleTooltipService -->
<simple-tooltip />
