import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';

import { Config } from './config';
import { PageStateService } from '../shared/services/page-state.service';
import { Dashboard } from '../dashboards/model/dashboard';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';
import { PageParameter } from '../helpers/types';
import { NavigationHelper } from '../helpers/navigation-helper';

@Injectable({
  providedIn: 'root',
})
export class ComponentsPageGuard {
  private readonly router = inject(Router);
  private readonly config = inject(Config);
  private readonly pageStateService = inject(PageStateService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const previousPage = this.pageStateService.pageType();
    const currentPage = this.getCurrentPage(state.url);

    this.pageStateService.hasPageChanged.set(previousPage !== currentPage);
    this.pageStateService.pageType.set(currentPage);
    this.pageStateService.pageIdField.set(null);

    const { idField } = this.config.appConfig.pages.find(p => p.id === this.pageStateService.pageType()) || {};
    this.pageStateService.pageIdField.set(idField);
    this.setPageParameters(route.queryParams);

    if (!idField) return true;
    if (this.pageStateService.pageId()) return true;

    const pageName = `${this.config.product}-${this.pageStateService.pageType()}`;
    const fingerPrint = `${pageName}.json-idField-${idField}-not-found`;

    this.pageStateService.pageType.set('');

    if (document.referrer && document.baseURI.includes('spinergie.com')) {
      /**
       * When no referrer is present, the user has entered the URL directly in the browser, in which case, we don't want to log it.
       * As it's not an error. Same if the base URI doesn't hold the spinergie domain, as it means it comes from an external
       * source (sso, login, etc)
       */
      console.error(
        new ErrorWithFingerprint(`Check config ${pageName} - ${idField} not found in params`, [fingerPrint]),
      );
    }

    this.router.navigate(['/bad-parameter']);
    return false;
  }

  private setPageParameters(queryParams: Params): void {
    const parameters: PageParameter[] = [];

    for (const [param, value] of Object.entries(queryParams)) {
      if (!Config.BROWSER_GLOBAL_FILTERS.includes(param) && Dashboard.isSimpleUrlParameter(param)) {
        parameters.push({ name: param, value });
      }
    }

    this.pageStateService.parameters.set(parameters);
  }

  protected getCurrentPage(url: string): string | null {
    const pageMatches = url.match(NavigationHelper.PAGE_REGEXP);

    return pageMatches?.[1] || null;
  }
}
