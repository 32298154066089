import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ErrorService } from './error.service';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.html',
  styleUrls: ['./page-not-found.css'],
  standalone: true,
})
export class PageNotFoundComponent {
  public url: string;
  public titleService: Title;

  /**
   * The navigation to this page is triggered by 2 possible eventss:
   *
   * 1- Either the user requested an non-existent component page (/dashboard/page/wrong_page).
   *      In that case, the redirection is made manually and we store the requested page in errorService.urlSource. \
   * 2- Either the user requested an non-existent route.
   *      In that case, the redirection has been automatically made by Angular router and errorService is empty.
   */
  constructor(titleService: Title, errorService: ErrorService) {
    this.url = errorService.urlSource ?? document.location.href.split('?')[0];
    this.titleService = titleService;
    titleService.setTitle('Spinergie | Page not found');
  }
}
