import { APP_BASE_HREF, DatePipe, DecimalPipe } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

import * as Sentry from '@sentry/angular';

import { AppRoutes } from './routes';
import { Config } from '../config/config';
import { DialogManager } from '../database/dialog-manager';
import { EntityDialogManager } from '../database/entity-dialog-manager';
import { ErrorModule } from '../error-pages/error.module';
import { BrowserHelper } from '../helpers/browser-helper';
import { DataLoader } from '../data-loader/data-loader';
import { AppComponent } from './app';
import { ZonelessEventPluginService } from './zoneless-event-plugin-service';
import { HomeRedirectService } from './home-redirect-service';
import { environment } from '../environments/environment';
import { ProductAnalyticsDirective, SearchBarComponent } from '../shared';
import { BookmarkerComponent, OsvProjectSidebarComponent, VesselFleetSidebarComponent } from '../user-saving/';
import { RefDataProvider } from '../data-loader/ref-data-provider';
import { SimpleTooltipComponent } from '../shared/simple-tooltip';
import { PearlButtonComponent, PearlIconComponent } from '../shared/pearl-components';
import { RemoveParentNodeDirective } from '../shared/directives/remove-parent-node.directive';
import { AppSwitcherComponent } from '../app-switcher/app-switcher.component';
import { PersistentCacheService } from '../data-loader/persistent-cache.service';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    // Angular material
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatExpansionModule,
    RemoveParentNodeDirective,
    MatTooltipModule,
    // Top level components and common directive
    PearlButtonComponent,
    PearlIconComponent,
    BookmarkerComponent,
    AppSwitcherComponent,
    OsvProjectSidebarComponent,
    VesselFleetSidebarComponent,
    SearchBarComponent,
    SimpleTooltipComponent,
    ProductAnalyticsDirective,
    // errors pages
    ErrorModule.forRoot(),
    PearlIconComponent,

    /*
     * Routing
     * 'eager' url update strategy to update the URL at the beginning of navigation. This helps with
     * serializing the state in the URL (needed for __selectedProject in URL)
     */
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'top',
      urlUpdateStrategy: 'eager',
      onSameUrlNavigation: 'reload',
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      /*
       * Register the ServiceWorker as soon as the app is stable
       * or after 30 seconds (whichever comes first).
       */
      registrationStrategy: 'registerImmediately',
      /*
       * Added in angular 16 migration PR comment to be removed
       *
       * This should be tested to make sure we don't unregister sevice worker for test & prod
       * environment, as well as specify a command to be able to test it locally
       */
      enabled: environment.serviceWorker,
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    Config,
    DialogManager,
    EntityDialogManager,
    BrowserHelper,
    DataLoader,
    RefDataProvider,
    {
      provide: APP_BASE_HREF,
      useValue: '',
    },
    DatePipe,
    DecimalPipe,
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: ZonelessEventPluginService,
      multi: true,
    },
    HomeRedirectService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: { type: 'component' },
    },
    PersistentCacheService,
    provideHttpClient(),
  ],
})
export class AppModule {}
