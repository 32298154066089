import { Component } from '@angular/core';
import { NgFor } from '@angular/common';

import { ErrorService } from './error.service';

@Component({
  selector: 'app-bad-parameter',
  templateUrl: './bad-parameter.html',
  styleUrls: ['./bad-parameter.css'],
  standalone: true,
  imports: [NgFor],
})
export class BadParameterComponent {
  public badParameters: string[];
  public pageName: string;

  constructor(errorService: ErrorService) {
    this.badParameters = errorService.parameters;
    this.pageName = errorService.pageName;
  }
}
