<div class="bookmarker">
  <div class="sidebar-header">
    <div class="title">Bookmarks</div>
    <div class="search-and-save">
      <pearl-form-field label="Search ..." iconPrefix="search">
        <input matInput [(ngModel)]="searchText" autocomplete="off" />
      </pearl-form-field>
      <pearl-button
        type="primary"
        icon="add"
        class="header-button"
        (click)="addNewBookmark()"
        [disabled]="!canAddNewBookmark"
        [ngClass]="{ 'header-button-disabled': !canAddNewBookmark }">
        Save current analysis
      </pearl-button>
    </div>
  </div>
  <div class="sidebar-list">
    <div id="top"></div>
    <a
      *ngFor="let bookmark of bookmarks | searchSidebarItem: searchText"
      class="item"
      (click)="navigateBookmark($event, bookmark)"
      [ngClass]="{ selected: checkIfBookmarkShouldAppearVisible(bookmark) }"
      [href]="bookmark.href">
      <div class="item-information">
        <div class="title-box">
          <div class="title" *ngIf="!bookmark.editing">{{ bookmark.title }}</div>
          <div class="title-editing" *ngIf="bookmark.editing">
            <pearl-form-field
              label="Enter your title"
              [showError]="bookmark.newTitle.invalid"
              [small]="true"
              [errorMessage]="getErrorMessage(bookmark.newTitle) ?? ''">
              <input
                matInput
                #titleInput
                (keydown.enter)="onTitleEnter($event, bookmark)"
                [formControl]="bookmark.newTitle"
                required
                cdkFocusInitial
                autocomplete="off" />
            </pearl-form-field>
          </div>
        </div>
        <div class="subtitle">{{ bookmark.dashboard | upperFirstLetter }}</div>
        <div class="date">{{ getFormattedDate(bookmark.dateBookmark) }}</div>
      </div>

      @if (!bookmark.editing && !bookmark.deleting && bookmark.vesselFleets?.length) {
        <pearl-button
          type="icon"
          icon="vessel"
          class="in-bookmark-icon"
          iconBadgeContent="{{ bookmark.vesselFleets?.length }}" />
      }
      @if (
        !bookmark.editing &&
        !bookmark.deleting &&
        bookmark.accessibleOsvProjects?.length &&
        config.userInfo?.accessibleOsvProjects?.length > 1
      ) {
        <pearl-button
          type="icon"
          icon="project"
          class="in-bookmark-icon"
          spinTooltip="{{ projectsToString(bookmark.accessibleOsvProjects) }}"
          iconBadgeContent="{{ bookmark.accessibleOsvProjects.length }}" />
      }
      <div class="action-buttons">
        @if (!bookmark.editing && !bookmark.deleting) {
          <pearl-button
            type="icon"
            icon="more"
            class="more-button"
            [matMenuTriggerFor]="addMenu"
            matTooltip="Actions"
            matTooltipPosition="above"
            (click)="preventDefault($event)" />
        }
        <mat-menu #addMenu="matMenu" class="edit-menu">
          @if (!bookmark.editing) {
            <pearl-button
              type="icon"
              icon="edit"
              class="edit-button"
              (click)="enterEditMode($event, bookmark)"
              color="primary"
              [matTooltip]="'Edit'" />
            <pearl-button
              type="icon"
              icon="delete"
              mode="warning"
              class="delete-button"
              (click)="enterDeletingMode($event, bookmark)"
              matTooltip="Delete" />
          }
        </mat-menu>
        @if (bookmark.editing) {
          <pearl-button
            type="icon"
            icon="save"
            class="save-button"
            (click)="saveBookmark($event, bookmark)"
            [disabled]="!bookmark.newTitle.value"
            matTooltip="Save" />
        }
        @if (bookmark.deleting) {
          <pearl-button
            type="icon"
            icon="delete"
            mode="warning"
            class="delete-button"
            (click)="deleteBookmark($event, bookmark)"
            matTooltip="Delete" />
        }
        @if (bookmark.editing || bookmark.deleting) {
          <pearl-button
            type="icon"
            icon="cancel"
            class="cancel-button"
            (click)="cancelAction($event, bookmark)"
            color="primary"
            matTooltip="Cancel" />
        }
      </div>
    </a>
  </div>
</div>
