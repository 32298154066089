import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output,
  ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';

import { DataLoader } from '../data-loader/data-loader';
import { DateHelper } from '../helpers/date-helper';
import { DialogManager } from '../database/dialog-manager';
import { Config } from '../config/config';
import { NavigationHelper } from '../helpers/navigation-helper';
import { AppInfoService } from '../app/app-info-service';
import { DatabaseHelper } from '../database/database-helper';
import { Bookmark } from './user-saving-types';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { SearchSidebarItemPipe, UpperFirstLetterPipe } from '../helpers/pipes';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { FailedResponse } from '../data-loader/data-loader.types';
import { OsvProject } from '../helpers/types';
import { PearlFormFieldComponent } from '../shared/pearl-components';
import { PearlButtonComponent } from '../shared/pearl-components/components/buttons/pearl-button.component';

@Component({
  selector: 'bookmarker',
  templateUrl: './bookmarker.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PearlFormFieldComponent,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    NgClass,
    MatIconModule,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    MatBadgeModule,
    SpinTooltipDirective,
    MatTooltipModule,
    MatMenuModule,
    UpperFirstLetterPipe,
    SearchSidebarItemPipe,
    PearlButtonComponent,
  ],
})
export class BookmarkerComponent implements AfterViewInit {
  private readonly dataLoader = inject(DataLoader);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly dialogManager = inject(DialogManager);
  private readonly router = inject(Router);
  private readonly appInfoService = inject(AppInfoService);
  private readonly productAnalyticsService = inject(ProductAnalyticsService);

  public readonly config = inject(Config);

  public bookmarks: Bookmark[] = [];
  public canAddNewBookmark: boolean = true;
  public searchText: string = '';

  @Input()
  currentDashboard: string = '';
  @Output()
  onBookmarkSelect = new EventEmitter<Bookmark>();
  @ViewChild('titleInput')
  titleInput: ElementRef<HTMLInputElement>;

  async ngAfterViewInit(): Promise<void> {
    await this.loadBookmarks();
    this.appInfoService.singleUserAction.subscribe(action => {
      this.deselectAllBookmarks();
      this.cdRef.detectChanges();
    });
    this.appInfoService.vesselFleetDeleted.subscribe(action => {
      this.loadBookmarks();
      this.cdRef.detectChanges();
    });
  }

  /**
   * Load the user bookmarks from the server and add them to the current bookmark list.
   */
  public async loadBookmarks(): Promise<void> {
    const url = '/base/saving/bookmarks';
    const results = await this.dataLoader.get<Bookmark[]>(url, { forceUpdate: true });

    /*
     * We preserve current bookmarks if we already have some.
     * This case can happen if we click on 'save current analysis' button where we add a bookmark
     * after its opening
     */
    this.bookmarks = [
      ...this.bookmarks,
      ...results.map(b => {
        // Merge URL and query params. We don't use URL params anymore
        b.queryParams = { ...b.urlParams, ...b.queryParams };
        b.urlParams = {};
        // Create the navigation link
        b.href = NavigationHelper.generateNavigationLink(b.url, b.queryParams);
        return { ...b, newTitle: new FormControl('', [Validators.required]) };
      }),
    ];
    // Last bookmarks are on top
    this.bookmarks = orderBy(this.bookmarks, b => b.dateBookmark, ['desc']);
    // Initialize accessible projects
    this.bookmarks.forEach(bookmark => {
      if (!bookmark.osvProjectIds) return;
      bookmark.accessibleOsvProjects = this.config.getAccessibleOsvProjects(bookmark.osvProjectIds);
    });
    this.cdRef.detectChanges();
  }

  public getFormattedDate(timestamp: number): string {
    return DateHelper.formatDatetime(timestamp, 'YYYY-MM-DD HH:mm');
  }

  /**
   * Save the given bookmark to the server.
   *
   * @param event     Mouse or keyboard event
   * @param bookmark  The bookmark to save
   */
  public async saveBookmark(event: Event, bookmark: Bookmark): Promise<void> {
    // Avoid trigger navigation event
    event.preventDefault();
    bookmark.dateBookmark = dayjs().valueOf();
    const updateBookmark = {
      updateBookmark: {
        id: bookmark.id,
        title: bookmark.newTitle.value,
        dateBookmark: bookmark.dateBookmark,
        url: bookmark.url,
        urlParams: bookmark.urlParams,
        queryParams: bookmark.queryParams,
        dashboard: bookmark.dashboard,
        vesselFleets: bookmark.vesselFleets,
        osvProjectIds: bookmark.osvProjectIds,
      },
    };
    const fullUrl = '/base/saving/bookmark-feedback';
    const saveResponse = await this.dataLoader.post<
      { updateBookmark: Bookmark },
      FailedResponse & { bookmarkId: number }
    >(fullUrl, updateBookmark);
    // If bookmark has been created or modified, backend returns the bookmarkId
    if (saveResponse.bookmarkId) {
      this.dialogManager.showMessage('Bookmark has been saved', 'success');
      bookmark.id = saveResponse.bookmarkId;
    } else {
      this.dialogManager.showMessage(saveResponse.error, 'error');
      return;
    }
    // We quit create new bookmark, so we can create a new bookmark
    this.canAddNewBookmark = true;
    bookmark.editing = false;
    bookmark.title = bookmark.newTitle.value;
    bookmark.href = NavigationHelper.generateNavigationLink(bookmark.url, {
      ...bookmark.urlParams,
      ...bookmark.queryParams,
    });
    bookmark.accessibleOsvProjects = this.config.getAccessibleOsvProjects(bookmark.osvProjectIds);
    this.selectBookmarkById(bookmark.id);
    this.cdRef.detectChanges();

    this.productAnalyticsService.trackAction('bookmarkCreated', { id: bookmark.id, url: bookmark.url });
  }

  /**
   * Delete the given bookmark.
   *
   * @param event     Mouse event triggering the action.
   * @param bookmark  The bookmark to delete.
   */
  public async deleteBookmark(event: MouseEvent, bookmark: Bookmark): Promise<void> {
    // Avoid trigger navigation event
    this.preventDefault(event);
    if (bookmark.id) {
      const deleteBookmark = {
        deleteId: bookmark.id,
      };
      const fullUrl = '/base/saving/bookmark-delete';
      try {
        await this.dataLoader.post<{ deleteId: number }, FailedResponse>(fullUrl, deleteBookmark);
        this.dialogManager.showMessage('Bookmark has been deleted', 'success');
      } catch {
        this.dialogManager.showMessage('An error occurred while deleting the bookmark', 'error');
      }
    } else {
      /*
       * If we're in this case it means we're deleting
       * the new bookmark we were creating. So we can add a new bookmark again
       */
      this.canAddNewBookmark = true;
    }
    const index = this.bookmarks.findIndex(b => b.id == bookmark.id);
    this.bookmarks.splice(index, 1);
    this.cdRef.detectChanges();
  }

  public enterEditMode(event: MouseEvent, bookmark: Bookmark): void {
    event.preventDefault();
    bookmark.editing = true;
    bookmark.newTitle = new FormControl(bookmark.title, [Validators.required]);
    bookmark.newTitle.markAsTouched();
  }

  /**
   * Add a new bookmark to the front list of bookmark.
   */
  public addNewBookmark(): void {
    // we can add only one bookmark at a time
    if (!this.canAddNewBookmark) {
      return;
    }
    this.canAddNewBookmark = false;
    const newBookmark: Bookmark = {
      id: null,
      title: '',
      newTitle: new FormControl('', [Validators.required]),
      dateBookmark: dayjs().valueOf(),
      dashboard: this.currentDashboard,
      url: null,
      urlParams: {},
      queryParams: Object.fromEntries(new URLSearchParams(location.search)),
      editing: true,
      isNew: true,
      vesselFleets: this.config.selectedFleets ? this.config.selectedFleets : [],
      osvProjectIds: this.config.selectedOsvProjectIds ? this.config.selectedOsvProjectIds : [],
    };
    let currentUrl = this.router.url;
    currentUrl = currentUrl.split('?')[0];
    newBookmark.url = currentUrl;
    newBookmark.newTitle.markAsTouched();
    this.bookmarks = [newBookmark, ...this.bookmarks];
    // Hack: Scrolls to top of bookmark list after adding a new bookmark
    const top = document.getElementById('top');
    if (top) {
      top.scrollIntoView();
    }
    this.cdRef.detectChanges();
    /*
     * force focus when we add a new bookmark
     * we already apply a cdkFocusInitial on input element
     * but it's ony working for initialization not when we
     * add manually a bookmark
     */
    this.titleInput.nativeElement.focus();
  }

  public getErrorMessage(title: FormControl): string {
    if (title.hasError('required')) {
      return 'You must enter a title';
    }

    return '';
  }

  public navigateBookmark(event: MouseEvent, bookmark: Bookmark): void {
    if (DatabaseHelper.isSpecialClick(event)) {
      return;
    }
    this.preventDefault(event);
    if (!bookmark || bookmark.editing) {
      return;
    }
    this.selectBookmarkById(bookmark.id);
    this.onBookmarkSelect.emit(bookmark);

    this.productAnalyticsService.trackAction('bookmarkApplied', { id: bookmark.id, url: bookmark.url });
  }

  public delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public selectBookmarkById(id: number): void {
    this.bookmarks.forEach(b => b.selected = b.id === id);
  }

  public deselectAllBookmarks(): void {
    this.bookmarks.forEach(b => b.selected = false);
  }

  /**
   * Even if a bookmark has been selected by the user. The selected status
   * of this bookmark can change. We consider a bookmark as selected if the user
   * select it and the current state is exactly the same than the bookmark state (query params and url params)
   */
  public checkIfBookmarkShouldAppearVisible(bookmark: Bookmark): boolean {
    if (!bookmark.selected) {
      return false;
    }

    return bookmark.dashboard == this.currentDashboard;
  }

  public enterDeletingMode(event: MouseEvent, bookmark: Bookmark): void {
    this.preventDefault(event);
    bookmark.deleting = true;
  }

  public cancelAction(event: MouseEvent, bookmark: Bookmark): void {
    this.preventDefault(event);
    /*
     * If we cancel and the bookmark has not id it means we were creating this bookmark
     * so we have to delete it
     */
    if (!bookmark.id) {
      this.deleteBookmark(event, bookmark);
      return;
    }
    bookmark.deleting = false;
    bookmark.editing = false;
    bookmark.newTitle = new FormControl('', [Validators.required]);
  }

  public preventDefault(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public onTitleEnter(event: Event, bookmark: Bookmark): void {
    if (!bookmark.newTitle || !bookmark.newTitle.value) {
      return;
    }
    this.saveBookmark(event, bookmark);
  }

  public projectsToString(accessibleProjects: OsvProject[]): string {
    return accessibleProjects.map(project => project.title).join(', ');
  }
}
